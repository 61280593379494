import React from "react";
import {
  withAuthUser,
  withAuthUserTokenSSR,
  AuthAction,
} from "next-firebase-auth";

import FirebaseAuth from "../components/FirebaseAuth";
import Layout from "../components/Layout";

import styles from "../styles/pages/Login.module.scss";
import { useRouter } from "next/dist/client/router";
import getContent from "../utils/getContent";

const Auth = () => {
  const router = useRouter();
  const locale = router.locale || "it";
  return (
    <Layout>
      <div className={styles["sub-title"]}>
        {getContent(
          "LOGIN_TITLE",
          process.env.NEXT_PUBLIC_PURCHASE_ENV,
          locale
        )}
      </div>
      <div className={styles["body-text"]}>
        {getContent("LOGIN_TEXT", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
      </div>

      <div>
        <FirebaseAuth />
      </div>
    </Layout>
  );
};

export const getServerSideProps = withAuthUserTokenSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})();

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(Auth);
